@import ../../styles/15percent/_variables

.header
  text-align: center

.container
  display: flex
  flex-direction: column
  align-items: center

  label
    margin-top: 20px !important

.step
  display: flex
  justify-content: center
  align-items: flex-start
  min-height: 50vh

.form
  width: 280px
  min-height: 60vh
  margin: 20px 0 10px

  input
    width: 100%

  @media (min-width: $breakpoint-tablet-portrait)
    width: 480px
    margin: 40px 0 20px
  
  .group
    align-self: center

.name
  display: flex
  flex-direction: column
  width: 100%

  @media (min-width: $breakpoint-tablet-portrait)
    flex-direction: row

    > *
      margin-top: 0 !important

    *:first-child
      margin-right: 20px

.message
  margin-top: 40px

  p
    margin: 5px 0

.submit,
.form .submit
  align-self: center
  margin-top: 60px

.error
  font-weight: 600
  color: $umber


.submit-container
  display: flex
  flex-direction: row
  justify-content: center
  align-items: center
  margin-top: 20px

  @media (min-width: $breakpoint-tablet-portrait)
    margin-top: 60px

.file,
.back,
.next
  padding: 10px 40px
  margin: 0 20px
  font-size: 0.675rem
  // font-weight: 600
  border-radius: 100px
  border: 1px solid $bg-light
  // text-transform: uppercase
  // background-color: $ivory

  @media (hover: hover) and (pointer: fine)
    transition: all 0.3s ease-in-out

    &:hover
      background-color: $bg-light
      color: $font-dark


// input[type="file"]
//   position: absolute
//   width: 0
//   height: 0
//   left: -99999px

.upload
  display: flex
  justify-content: space-between
  align-items: flex-end

.file
  font-size: 0.5rem
  margin: 10px 10px 0 0
  white-space: nowrap
  cursor: pointer

.filename
  @extend %font-header
  word-break: break-all
  font-size: 0.675rem
  
