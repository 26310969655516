@import ../../styles/15percent/_variables

.wrapper
  position: fixed
  width: 100%
  z-index: 100
  transition: all 0.3s ease-in-out

  &.hide
    transform: translate3d(0, -110%, 0)
    transition-delay: 0s

  &.dark
    color: $font-light

    .header
      border-bottom: 1px solid $bg-light

      &.collapsed
        background-color: $bg-rust

      .link:after
        background-color: $bg-light
    
    .menutoggle
      &:after, i:before, i:after
        background-color: $bg-light !important
    
    .accounttoggle .close
      &:before, &:after
        background-color: $bg-light
    .awardtoggle .closee
      &:before, &:after
        background-color: $bg-light
  
  &.solid
    .header
      background-color: $bg-light


  // &.publish
  //   .header

.header
  display: flex
  justify-content: center
  width: 100%
  height: $header-height-mobile
  background-color: transparent
  transition: all 0.3s ease-in-out
  border-bottom: 1px solid transparent
  // background-color: $bg-light

  // &:not(.transparent)
  //   background-color: $bg-light

  //   &.search
  //     background-color: transparent //$gradient-start

  &.down
    // transform: translate3d(0, -120%, 0)
    transition-delay: 0s
  
  // &.hide
  //   transform: translate3d(0, -110%, 0)
  //   transition-delay: 0s
  
  &.collapsed
    background-color: $bg-light
  //   &:not(.transparent)
  //     background-color: $bg-light

  //     &.search
  //       background-color: transparent //$gradient-start

    .logowrapper .label
      opacity: 0

  // .icon
  //   position: relative
  //   width: 30px
  //   height: 30px
  //   color: $font-dark
  //   cursor: pointer
  //   z-index: 101

  .link
    position: relative

    &:after
      content: ''
      position: absolute
      top: 100%
      left: 0
      width: 100%
      height: 1px
      background-color: $bg-light
      transform: scaleX(0)
      transition: transform 0.2s ease-in-out
    
    &.disabled
      opacity: 0.5
      user-select: none
      pointer-events: none
    
    @media (min-width: $breakpoint-tablet-landscape)
      &:after
        background-color: $font-dark

    @media (hover: hover) and (pointer: fine)
      &:not(.selected):not(.disabled):hover:after
        transform: scaleX(1)


  .link.selected
    font-weight: 500

    &:after
      transform: scaleX(1)
  
  .menutoggle
    position: relative
    display: flex
    justify-content: space-around
    align-items: center
    padding: 0
    margin: 0
    width: 32px
    z-index: 101

    &:before
      content: ''
      padding-bottom: 100%

    &:after
      content: ""
      position: absolute
      width: 24px
      height: 1px
      background-color: $font-dark
      top: 15px
      left: 4px
      z-index: 100
      transition: all 100ms ease-out

    i:before, i:after
      content: ""
      position: absolute
      width: 24px
      height: 1px
      border-radius: 12px
      left: 4px
      background-color: $font-dark
      transform-origin: center center
      transition: all 200ms ease-out

    i:before
      top: 8px

    i:after
      top: 22px
    
    &.open
      &:after
        display: none

      i:before
        background-color: $bg-light
        transform: rotate(45deg)
        top: 16px

      i:after
        background-color: $bg-light
        transform: rotate(-45deg)
        top: 16px

  .nav
    display: flex
    background-color: transparent
    transition: background-color 0.05s ease-in-out
    z-index: 1
    // opacity: 0
    // transition: opacity 0.3s ease-in-out 1s
    // border-radius: 50%
    // transition: border-radius .5s ease, top .5s ease .1s, left .5s ease .1s, transform .5s ease .1s
    // background-color: $ebony
  
  @media (min-width: $breakpoint-tablet-landscape)
    .logowrapper,
    .right
      flex: 1
    
    .right
      justify-content: flex-end
    
    .nav li
      margin: 0 20px

      &:first-child
        margin-left: 0
      
      &:last-child
        margin-right: 0

  .awardtoggle
  .accounttoggle
    position: relative

    span,
    .close
      transition: opacity 0.3s ease-in-out

    .close
      opacity: 0

      &:before, &:after
        content: ""
        position: absolute
        width: 24px
        height: 1px
        border-radius: 12px
        top: 12px
        right: 0
        transform-origin: center center
        transition: all 0.2s ease-in-out
      
      &:before
        background-color: $font-dark
        transform: rotate(45deg)
        

      &:after
        background-color: $font-dark
        transform: rotate(-45deg)
    
    &.open
      @media (hover: hover) and (pointer: fine)
        &.link:hover
          &:after
            transform: scaleX(0)
      span
        opacity: 0

      .close
        opacity: 1

  .modal
    position: fixed
    right: 0
    top: 0
    width: 0
    height: 0
    background-color: transparent
    transition: background-color 0.05s ease-in-out
    z-index: 1

    &:before
      content: ''
      position: absolute
      right: -50vh
      top: -50vh
      width: 250vh
      height: 250vh
      border-radius: 50%
      background-color: $terracotta
      transform: scale3d(0, 0, 1)
      transform-origin: 100% 0
      transition: transform .7s ease-in-out 0.1s
    
    &.open
      width: 100vw
      height: 100vh
      right: 0
      top: 0
      background-color: $terracotta
      transition-delay: 0.8s
      // border-radius: 0
      .nav
        opacity: 1
        transition: opacity 0.5s ease-in-out 0.5s, height 0.1s ease-in-out 0.1s
        flex-direction: column
        height: auto

      &:before
        transform: scale3d(1, 1, 1)
        // transition: border-radius .5s ease .1s, top .5s ease, left .5s ease, transform .5s ease

    .nav
      display: flex
      justify-content: center
      align-items: flex-start
      width: max-content
      height: 100%
      padding: 0 40px 20px
      opacity: 0
      transition: opacity 0.5s ease-in-out, height 0.1s ease-in-out 1s

      @media (min-width: $breakpoint-desktop)
        padding: 0 80px
      
      @media (min-width: $max-content-width)
        margin-left: calc((100vw - #{$max-content-width}) / 2)

      .separator
        position: relative
        width: 100%
        height: 2px
        background-color: $bg-light
        margin: 40px 0 0

      .account
        display: flex
        flex-direction: column
        width: 100%
        align-items: flex-start
        margin-bottom: 40px

        ul
          display: flex
          flex-direction: column

      .profile
        background-color: $bg-light
        color: $font-dark
        border-radius: 50%
        width: 30px
        height: 30px
        z-index: 3
        font-size: 0.75rem
        text-align: center
        font-weight: bold
        // margin: 0 10px
      
      // .notauth
      //   display: flex
      //   width: 100%
      //   // justify-content: space-between

      //   > *:not(:last-child)
      //     margin-right: 20px

      //     @media (min-width: $breakpoint-desktop)
      //       margin-right: 40px

      .link
        @extend %font-header
        font-size: 2rem
        font-weight: 300
        display: inline-block
        position: relative
        color: $font-light
        text-align: left
        margin: 5px 0
        padding: 5px 0

        // &:not(.disabled):hover,
        &.selected
          font-weight: 500

        @media (min-width: $breakpoint-desktop)
          margin: 10px 0
        
        @media (max-height: $breakpoint-tablet-portrait)
          font-size: 1.5rem
          margin: 5px 0

        // &:after
        //   content: ''
        //   position: absolute
        //   width: 100%
        //   transform: scaleX(0)
        //   height: 1px
        //   bottom: 0
        //   left: 0
        //   background-color: $bg-light
        //   transform-origin: bottom right
        //   transition: transform 0.5s ease-out

        // &:not(.disabled):hover:after,
        // &.selected:after
        //   transform: scaleX(1)
        //   transform-origin: bottom left

      // .account
      //   .link
      //     @extend %font-body
      //     display: block
      //     font-size: 1rem
      //     font-weight: 500
      //     text-transform: uppercase

      //     &.inline
      //       display: flex

      //     @media (min-width: $breakpoint-tablet-portrait)
      //       &.business
      //         display: flex
        
      //     @media (max-height: $breakpoint-tablet-portrait)
      //       font-size: 0.875rem
      //       margin: 5px 0
      
      .social
        display: flex
        margin-top: 10px

        svg
          fill: $ivory

        a
          display: block
          transform: scale(0.8)

          &:first-child
            margin-left: 0 !important
        
        @media (min-width: $breakpoint-tablet-portrait)
          a
            transform: scale(0.9)
        
        @media (min-width: $breakpoint-tablet-landscape)
          margin-top: 40px

          a
            transform: scale(1)
            margin: 0 10px
        
        @media (max-height: $breakpoint-tablet-portrait)
          margin-top: 10px
          a
            transform: scale(0.8)
            margin: 0       

  .navbar
    display: flex
    flex-direction: row
    justify-content: space-between
    align-items: center
    width: 100%
    max-width: $max-content-width
    padding: 0 20px

    @media ( min-width: $breakpoint-tablet-portrait )
      padding: 0 40px

    @media ( min-width: $breakpoint-desktop )
      padding: 0 80px
      
  .divider
    width: 40%
    height: 1px
    margin: 15px 0
    background-color: $antique-brass

  .award
    display: flex
    flex-direction: column
    margin-top: 40px

    .signout
      font-weight: 200 !important

    .tooltip
      font-size: 0.75rem
      font-weight: 200
      margin-top: 0
      color: $antique-brass

    @media ( min-width: $breakpoint-tablet-landscape )
      position: absolute
      top: calc(100% - 0.5px)
      margin-top: 0px
      margin-left: -20px
      color: $font-dark
      overflow: hidden
      transform: translate3d(0, -200%, 0)
      transition: transform 0.01s linear 0.3s
      z-index: -1

      .link:after
        background-color: $font-dark !important

      &.dark
        color: $font-light

        .link:after
          background-color: $bg-light !important
        

        ul 
          background-color: $bg-rust
        
      ul
        padding: 10px 30px 20px 20px
        border-radius: 0 0 20px 20px
        background-color: $bg-light
        transform: translate3d(0, -100%, 0)
        transition: all 0.3s ease-in-out

        li:not(.signout)
          font-weight: 400
          line-height: 2em

      &.open
        transform: translate3d(0, 0, 0)
        transition-delay: 0s
        z-index: 1

        ul
          transform: translate3d(0, 0, 0)

  .account
    display: flex
    flex-direction: column
    margin-top: 40px

    .signout
      font-weight: 200 !important

    .tooltip
      font-size: 0.75rem
      font-weight: 200
      margin-top: 0
      color: $antique-brass

    @media ( min-width: $breakpoint-tablet-landscape )
      position: absolute
      right: 20px
      top: calc(100% - 0.5px)
      margin-top: 0px
      color: $font-dark
      overflow: hidden
      transform: translate3d(0, -200%, 0)
      transition: transform 0.01s linear 0.3s
      z-index: -1

      .link:after
        background-color: $font-dark !important

      &.dark
        color: $font-light

        .link:after
          background-color: $bg-light !important
        

        ul 
          background-color: $bg-rust
        
      ul
        padding: 10px 30px 20px 20px
        border-radius: 0 0 20px 20px
        background-color: $bg-light
        transform: translate3d(0, -100%, 0)
        transition: all 0.3s ease-in-out

        li:not(.signout)
          font-weight: 400
          line-height: 2em

      &.open
        transform: translate3d(0, 0, 0)
        transition-delay: 0s
        z-index: 1

        ul
          transform: translate3d(0, 0, 0)


    @media ( min-width: $breakpoint-desktop )
      right: 60px
    
    @media ( min-width: $max-content-width )
      right: calc((100vw - #{$max-content-width}) / 2 + 60px)

  .menu
    position: relative
    display: flex
    justify-content: center
    align-items: center
    height: 100%

    > *:not(:first-child)
      margin-left: 10px

      @media ( min-width: $breakpoint-tablet-portrait )
        margin-left: 20px

  .item
    @extend %font-body-bold
    font-size: 0.75rem
    font-weight: 700
    color: $ivory
  
  .logowrapper
    height: 34px
    z-index: 101

    a
      display: block
      width: 80px
      height: 100%

    svg
      width: auto
      height: 100%

  .logo
    width: auto
    height: 100%

  @media (min-width: $breakpoint-tablet-portrait)
    height: $header-height-tablet

    .logowrapper,
    .secondary
      min-width: 100px

    .logowrapper
      height: 40px
      transform-origin: 0 50%
      transition: transform 0.3s ease-in-out 0.3s

      a
        width: 94px

    &.collapsed
      height: 50px
      // transform: translate3d(0, -10px, 0)

      .logowrapper
        transform: scale3d(0.6, 0.6, 1)

  @media (min-width: $breakpoint-desktop)
    height: $header-height-desktop

    .logowrapper,
    .secondary
      min-width: 130px

    &.collapsed
      height: 60px
      // transform: translate3d(0, -20px, 0)

      .logowrapper
        transform: scale3d(0.6, 0.6, 1)
        

    .navbar
      flex-direction: row
      align-items: center

    .menu
      flex-direction: row
      // flex: 1

      &.secondary
        justify-content: flex-end

    .item
      margin: 0 20px
    
    .signup
      margin-left: 10px

    .logowrapper
      height: 55px

      a
        width: 130px
