@import ../../styles/15percent/variables

.wrapper
  position: relative
  width: 100%
  height: 280px
  margin-top: 40px
  overflow: hidden
  cursor: grab

  &.touch
    overflow-x: auto
    -ms-overflow-style: none
    scrollbar-width: none

    &::-webkit-scrollbar
      display: none

    .categories
      overflow-x: hidden

  &.dragging
    cursor: grabbing

  @media (min-width: $breakpoint-mobile-landscape)
    height: 360px

  @media (min-width: $breakpoint-tablet-portrait)
    height: 400px

  @media (min-width: $breakpoint-desktop)
    height: 480px

  @media (min-width: $breakpoint-desktop-large)
    height: 560px
  
.categories
  position: absolute
  display: flex
  justify-content: flex-start
  will-change: transform

  &.animating
    transition: transform 0.5s ease-out
  // overflow-x: hidden

.arrow
  position: absolute
  width: 60px
  height: 100%
  top: 0
  z-index: 99

  &.disabled
    pointer-events: none

  &:before
    content: ''
    position: absolute
    top: 50%
    width: 40px
    height: 40px
    margin-top: -40px
    background-color: $bg-light
    border-radius: 50%
  
  &:after
    content: ''
    position: absolute
    top: 50%
    width: 40px
    height: 40px
    margin-top: -40px
    background-image: url('/images/button-arrow-icon-dark.svg')
    background-repeat: no-repeat
    background-position: 50%

  &.left
    left: 0
    &:before,
    &:after
      left: 20px
    
    &:after
      transform: rotate(180deg)

  &.right
    right: 20px
    &:before,
    &:after
      right: 20px
    
    // @media (min-width: $breakpoint-tablet-portrait)
    //   right: 40px

    // @media (min-width: $breakpoint-desktop)
    //   right: 80px

  @media (hover: hover) and (pointer: fine)
    opacity: 1
    transition: opacity 0.3s ease-in-out

    &.disabled
      opacity: 0
