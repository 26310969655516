@import ../../styles/15percent/_variables

.footer
  position: fixed
  display: flex
  flex-direction: column
  align-items: center
  width: 100%
  height: $footer-height-mobile
  bottom: 0
  background-color: $nutmeg
  color: $font-light
  opacity: 0

  &.show
    opacity: 1

  h4
    margin: 0

  @media (min-width: $breakpoint-tablet-landscape)
    height: $footer-height-desktop

  .container
    position: relative
    flex: 1
    display: flex
    flex-direction: column
    align-items: center
    justify-content: center
    padding: 20px 20px 0
    width: 100%
    max-width: $max-content-width

    .top
      display: flex
      flex-direction: column
      width: 100%

    .logowrapper
      height: 30px
      margin-bottom: 20px

    .logo
      width: auto
      height: 100%
    
    .newsletter
      min-height: 80px
      max-width: 640px
      padding: 0 20px
      margin-top: 10px

    .follow
      position: relative
      display: flex
      width: 100%
      flex-direction: column
      align-items: center
      text-align: center

      .form
        flex-direction: column
        align-items: center
        text-align: center
        width: 280px
        border: 1px solid $color-light
        border-radius: 50px
      
      .error
        text-align: left
        color: $orange

      .inputs
        display: flex
        width: 100%
        align-items: center
        height: 35px
        margin: 0

        .input
          color: $thistle
          -webkit-text-fill-color: $thistle
          border-bottom: none
          padding: 0 0 0 15px

          &::placeholder
            color: $thistle
            font-size: 12px
        
        .join
          display: flex
          flex-direction: column
          justify-content: center
          width: 25px
          height: 100%
          padding: 0
          margin: 0 12px 0 5px

          svg
            width: 100%
        
        @media (min-width: $breakpoint-tablet-landscape)
          height: 41px

          .join
            width: 27px
            margin: 0 15px 0 5px
  
  .contact
    display: flex
    height: 100%
    justify-content: space-around
    align-items: center

    svg
      fill: $font-light

    a
      display: block
      transform: scale(0.8) 
  
  .menu
    display: flex
    align-items: flex-start
    justify-content: center
    margin-top: 40px
    // flex-direction: column
    // text-align: center

    ul li
      margin: 10px 0

    .link
      margin: 5px
  
  .submenu
    flex: 1
    margin: 0

    &:not(:last-child)
      margin-right: 10px
    
    .item
      position: relative
      
      a,
      button
        position: relative
        text-align: left

      @media (hover: hover) and (pointer: fine)
        a:after
          content: ''
          position: absolute
          top: 100%
          left: 0
          width: 100%
          height: 1px
          background-color: $bg-light
          transform-origin: 0 50%
          transform: scaleX(0)
          transition: transform 0.2s ease-in-out

        &:hover
          a:after
            transform: scaleX(1)

  .bottom
    display: flex
    flex-direction: column
    justify-content: center
    align-items: center
    width: 100%
    margin-top: 40px

    .wrapper
      display: flex
      width: 100%
      justify-content: center
      background-image: linear-gradient(to left, $gradient-end, $gradient-start)
    
    .copyright
      font-size: 0.675rem
      margin-top: 40px
    
    .link
      color: $persimmon
      text-transform: uppercase
      font-size: 0.875rem
      font-weight: 600
      margin: 20px         

@media (min-width: $breakpoint-mobile-landscape)
  .footer .submenu
    flex: unset
    &:not(:last-child)
      margin-right: 40px


@media (min-width: $breakpoint-tablet-portrait)
  .footer
    .container
      padding: 40px 40px 0

      .follow
        .inputs
          display: flex
          flex-wrap: nowrap

          .input
            width: 240px

            &::placeholder
              font-size: 0.675rem
        
        .join
          padding: 0 4px 10px 10px
          color: $thistle
          font-size: 0.675rem
    
    .submenu
      &:not(:last-child)
        margin-right: 80px

    .bottom
      .contact
        a
          transform: scale(0.9)  

@media (min-width: $breakpoint-tablet-landscape)
  .footer
    .container
      padding: 80px 80px 0
    
      .newsletter
        padding: 0
    
      .contact
        justify-content: flex-start
        align-items: flex-start
        list-style-type: none
        padding: 0

        svg
          width: 30px
          margin: 0
    
      .top
        flex-direction: row
        justify-content: space-between
        align-items: flex-start

        .follow
          flex: unset
          width: auto
          align-items: flex-start

        .logowrapper
          position: absolute
          top: 10px
          left: 0
          height: 55px
      
      .bottom
        align-items: flex-start
      
      .menu
        justify-content: flex-end
        text-align: left
        margin-top: 0
      
    .submenu
      &:not(:last-child)
        margin-right: 80px

@media (min-width: $breakpoint-desktop)
  .footer 
    .submenu
      &:not(:last-child)
        margin-right: 120px