@import ../../styles/15percent/_variables

.container
  position: relative
  width: 100%
  background-color: $eclipse
  color: $font-light

  .inner
    display: flex
    flex-direction: column
    justify-content: flex-end
    align-items: center
    min-height: 60px
    padding: 5px 20px
    margin: 0 auto

    .buttons
      position: relative
      display: flex
      justify-content: center
      width: 100%

    button
      margin: 0 10px 5px
      width: 50%
      max-width: 150px
      height: 30px
      font-size: 14px
      border-radius: 20px

      &.edit
        background-color: $bg-light
        color: $font-dark
      
      &.publish
        background-color: $brown-rust
        color: $font-light

        &.disabled
          opacity: 0.5

      @media (hover: hover) and (pointer: fine)
        transition: all 0.2s ease-in-out

        &:hover
          &.edit
            background-color: $bg-brass
            color: $font-light
          
          &:not(.disabled).publish
            background-color: $nutmeg
            color: $font-light

        
    
    .note
      font-size: 14px
      margin: 0 0 10px
    
    .error
      font-size: 14px
      color: $thistle

    @media (min-width: $breakpoint-tablet-portrait)
      flex-direction: row
      padding: 5px 40px

      .buttons
        width: auto

      button
        width: 150px
        height: 40px
        font-size: 0.875rem
        margin: 0 0 0 10px
      
      .note
        width: auto
        font-size: 0.875rem
        margin: 0

    @media (min-width: $breakpoint-desktop)
      padding: 5px 80px
  
  .publisherror
    position: absolute
    top: calc(100% + 15px)
    right: 0
    display: flex
    align-items: center
    background-color: $bg-light
    padding: 0 20px
    border-radius: 12px
    z-index: 100000
    box-shadow: 0px 2px 4px rgba(0,0,0,0.25)

    .error
      color: red
      margin-right: 5px

    .close
      position: relative
      // align-self: flex-end
      width: 20px
      height: 20px
      margin: 0
      // top: 10px
      // right: 20px

      i
        width: 100%
        height:100%

        &:before,
        &:after
          content: ''
          position: absolute
          top: 50%
          left: 0
          width: 20px
          height: 1px
          background-color: $bg-dark
          transform: rotate(-45deg)
        
        &:after
          transform: rotate(45deg)
