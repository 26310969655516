$white: #FFF8F0
$light: #D8D8D8
$black: #000000

//Color Names: https://colors.artyclick.com/color-name-finder/
$white-floral: #FFF8F0
$white-antique: #F5EBDE
$antique-brass: #CD8678
$pink-tan: #E59B8B
$brown-rust: #AB5742
$nutmeg: #893B2B
$eclipse: #371C16
$orange: #F79D51
$salmon: #BF6E59
$umber: #8E301D
$nutmeg-alt: #7F4030

$nutmeg-30: rgba(137, 59, 43, 0.3)
$brown-rust-70: rgba(171, 87, 66, 0.7)
$white-50: rgba(255, 248, 240, 0.5)
$antique-30: rgba(245, 235, 222, 0.3)
$antique-70: rgba(245, 235, 222, 0.7)
$salmon-70: rgba(191, 110, 89, 0.7)


// BACKGROUND
$bg-light: $white-floral
$bg-dark: $eclipse

$bg-antique: $white-antique
$bg-brass: $antique-brass
$bg-rust: $brown-rust
$bg-salmon: $salmon
$bg-pink-tan: $pink-tan
$bg-nutmeg: $nutmeg
$bg-nutmeg-alt: $nutmeg-alt
$bg-nutmeg-30: $nutmeg-30

// GRADIENT
%bg-gradient-dark
  background: linear-gradient(155deg, $nutmeg, #AF5D49, #BB6D5C, #E39989, #F0A292)

%bg-gradient-sunset
  background: linear-gradient(155deg, #EA8B75, $orange 30%, #EA7F67 75%, #F09296)

// FONT
$font-dark: $nutmeg
$font-light: $white-floral


//NEW
$ebony: #2c1511
$coffee: #743a35
$sienna: #d78174
$persimmon: #e84326
$terracotta: #b6523b
$yam: #FD854C
$ivory: #f6eadb
$logo-light-color: #F6ECDF
$air: #fffcf7
$thistle: #d2b8b4
$midnight: #131624

$midnight-25: rgba(19, 22, 35, 0.25)
$midnight-50: rgba(19, 22, 35, 0.5)
$ivory-50: rgba(246, 234, 219, 0.5)
$ivory-75: rgba(246, 234, 219, 0.75)
$ivory-90: rgba(246, 234, 219, 0.9)
$coffee-75: rgba(116, 58, 53, 0.75)
$yam-75: rgba(253, 133, 76, 0.75)
$ebony-50: rgba(44, 21, 17, 0.5)
$ebony-75: rgba(44, 21, 17, 0.75)

$white-linen-50: rgba(245, 235, 222, 0.5)
$white-linen-60: rgba(245, 235, 222, 0.6)
$white-linen-80: rgba(245, 235, 222, 0.8)

$overlay-ivory: rgba(245, 235, 222, 0.95)
$overlay-sienna: rgba(210, 184, 180, 0.95)
$overlay-yellow: rgba(234, 185, 117, 0.95)


//OLD: NEED TO BE REMOVED:
// $tamarind: #371C16
// $pickled-bean: #6D3D27
// $nutmeg: #893B2B
// $toscany: #C0562F

// $porsche: #EAA675
// $negroni: #FFE2C1

$gradient-start: #DA5344
$gradient-end: #CD8678

// $terracotta-88: rgba(226,128,81, 0.88)

$color-dark: $nutmeg
$color-medium: $antique-brass
$color-light: $white-floral
// $color-overlay: $terracotta-88

$grey-light: #DDDDDD
$grey: #666666

$header-height-mobile: 42px
$header-height-tablet: 60px
$header-height-desktop: 100px

$footer-height-mobile: 550px
$footer-height-desktop: 500px

$max-content-width: 1920px
$max-text-width-tablet: 960px
$max-text-width: 1280px
$breakpoint-mobile: 320px
$breakpoint-mobile-xl: 375px
$breakpoint-mobile-landscape: 450px
$breakpoint-mobile-landscape-xl: 640px
$breakpoint-tablet-portrait: 768px
$breakpoint-tablet-landscape: 1024px
$breakpoint-desktop: 1280px
$breakpoint-desktop-macbook: 1440px
$breakpoint-desktop-large: 1600px

%font-header
  font-family: bilo, sans-serif
  font-weight: 700

%font-body
  font-family: bilo, sans-serif
  font-weight: 300

%font-body-bold
  font-family: bilo, sans-serif
  font-weight: 500