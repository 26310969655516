@import ../../styles/15percent/_variables

.container
  min-height: 100vh
  // for now we expect the sections above and below to need white background
  // this assumption will probably cause us problems later ...
  background-color: $white !important
  background-image: url('/images/dashboard-bg-shape-spotlight.svg')
  background-repeat: no-repeat
  background-size: cover
  background-position: 10% 50%
  padding: 35% 0 15%

  @media (min-width: $breakpoint-tablet-portrait)
    min-height: unset
    padding: 12% 0 10%

  @media (min-width: $breakpoint-tablet-landscape)
    background-size: 110% 100%
    background-position: 40% 50%
    padding: 10vw 0 12vw
  
  @media (min-width: $breakpoint-desktop-large)
    background-size: 110% 100%
    background-position: 60% 50%
    padding: 10vw 0 10vw