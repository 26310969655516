@import ../../styles/15percent/_variables

.modalContainer
  width: 0
  height: 0
  opacity: 0
  position: fixed
  top: 0
  left: 0
  z-index: 900
  background-color: rgba(128,128,128,.77)
  will-change: transform, opacity
  transform: scale(1.15)
  transition: transform 0.1s cubic-bezier(0.465, 0.183, 0.153, 0.946), opacity 0.1s cubic-bezier(0.465, 0.183, 0.153, 0.946)

  &.open
    transform: scale(1)
    transition: transform 0.3s cubic-bezier(0.465, 0.183, 0.153, 0.946), opacity 0.3s cubic-bezier(0.465, 0.183, 0.153, 0.946)
    width: 100vw
    height: 100vh
    opacity: 1

    .modal
      top: 50%
      left: 50%
      transform: translate(-50%,-50%)
      z-index: 901
      width: 80vw
      max-width: 1740px
      position: fixed
      //box-shadow: 0 0 10px #222

      .contentContainer
        position: relative
        display: flex
        flex-direction: column
        justify-content: center
        align-items: center

        .content
          text-align: center
          padding: 20px
          min-height: 366px
          display: flex
          flex-direction: column
          justify-content: center
          align-items: center

          .button
            width: 100%
            display: block
            padding: 10px
            font-size: 20px
            font-weight: 600
            width: 170px
            margin: 0 auto

        @media (min-width: $breakpoint-tablet-portrait)
          flex-direction: row

          .imageContainer
            width: 50%
            border-right: 2px solid #fff

          .content
            width: 50%
            min-height: unset
            .contentInner
              max-width: 560px
              margin: 0 auto

    .closeContainer
      position: absolute
      top: 0
      right: 10px

      .btnClose
        position: relative
        align-self: flex-end
        width: 16.5px
        height: 16.5px

        i
          width: 100%
          height: 100%

          &:before,
          &:after
            content: ''
            position: absolute
            top: 50%
            left: 0
            width: 16.5px
            height: 1px
            transform: rotate(-45deg)

          &:after
            transform: rotate(45deg)

.popupImages
  display: grid
  grid-auto-flow: column
  align-items: center
  justify-content: center
  column-gap: 2rem

.partnerImage
  position: relative
  width: 160px

.partnerImage:before
  content: '&'
  top: -3px
  left: -1.4rem
  position: absolute
  font-size: 1rem
  font-weight: 500

.modalContainerDark.open .modal
  background-color: $black
  border: 2px solid $white

.modalContainerDark.open .modal .content .button
  background-color: $white
  color: $black
  &:hover
    background-color: $white-antique

.modalContainerDark.open .modal .content p
  color: $white
  font-size: 20px
  font-weight: 600
  padding: 0 30px

.modalContainerDark.open .modal .content svg
  width: 170px
  margin: 0 auto

  @media (min-width: $breakpoint-desktop)
    width: 205px

.modalContainerDark.open .closeContainer .btnClose i
  &:before,
  &:after
    background-color: $black

    @media (min-width: $breakpoint-tablet-portrait)
      background-color: $bg-light

.modalContainerLight.open .modal
  background-color: $white-floral
  border: 2px solid #56111A

.modalContainerLight.open .modal .content .button
  background-color: $nutmeg
  color: $white-floral
  &:hover
    background-color: $white-antique

.modalContainerLight.open .modal .content p
  color: $nutmeg
  padding: 0 30px

.modalContainerLight.open .closeContainer .btnClose i
  &:before,
  &:after
    background-color: $white-floral

    @media (min-width: $breakpoint-tablet-portrait)
      background-color: $nutmeg

.modalContainerLight.open h3
  font-size: 2rem
  font-weight: 600

.modalContainerLight.open p
  font-size: 85%
  font-weight: 600
  margin-bottom: 0

.modalContainerLight.open .content svg
  width: 95px
  height: 50px
  margin: 0 auto