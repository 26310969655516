@import ../../styles/15percent/_variables

@keyframes rotate
  0%
    transform: rotate(0deg)
  100%
    transform: rotate(360deg)

@media (min-width: $breakpoint-tablet-landscape)

.images
  position: relative
  display: flex
  justify-content: center
  align-items: center
  width: 100%
  max-width: 420px
  margin: 20px auto

  .blob
    position: absolute
    width: 100%
    right: 0
    animation: rotate 90s linear infinite

    @media (min-width: $breakpoint-tablet-landscape)
      width: 110%
      right: -5%

  .bottom
    position: relative
    width: 100%
    max-width: 270px
    height: 320px

    .one, 
    .two
      border-radius: 8px

    .one
      position: absolute
      width: 50%
      top: 12%
      right: 5%
    
    .two
      position: absolute
      width: 75%
      left: 5%
      bottom: 12%
  
  @media (min-width: $breakpoint-mobile-xl)
    .bottom
      .one
        width: 60%
        top: 5%
        right: 0
      
      .two
        width: 75%
        left: 0
        bottom: 10%

  
  @media (min-width: $breakpoint-mobile-landscape)
    margin-top: 40px

    .bottom
      max-width: 480px
      height: 400px

      .one
        width: 40%
        right: 10%
        top: 5%
      
      .two
        width: 65%
        left: 10%
        bottom: 5%
  
  @media (min-width: $breakpoint-tablet-portrait)
    max-width: 640px
    background-size: 70% auto

    .bottom
      max-width: 480px
      height: 440px

      .one, 
      .two
        border-radius: 12px
  
  @media (min-width: $breakpoint-tablet-landscape)
    flex: 3
    justify-content: flex-end
    background-size: auto 100%
    background-position: 100% 50%
    padding: 40px
    margin-left: 40px
    margin-right: 0

    .bottom
      margin-top: 0

      .one, 
      .two
        border-radius: 16px

      .one
        width: 50%
        right: 0
        top: 0
      
      .two
        width: 80%
        left: 0
        bottom: 0

  @media (min-width: $breakpoint-desktop)
    padding: 80px
    max-width: 720px

    .bottom
      width: 100%
      max-width: 960px
      height: 500px
