@import ../../styles/15percent/_variables

.header
  text-align: center

.container
  display: flex
  flex-direction: column
  align-items: center
  scrollbar-color: rgba(0,0,0,0.2) $nutmeg

  ::-webkit-scrollbar
    width: 6px

  /* Track */
  ::-webkit-scrollbar-track
    width: 2px
    background: rgba(0,0,0,0.2)

  /* Handle */
  ::-webkit-scrollbar-thumb
    width: 6px
    background: $midnight

.step
  display: flex
  justify-content: center
  align-items: flex-start
  min-height: 50vh

.form
  width: 280px
  max-height: 40vh
  overflow-y: scroll
  margin: 20px 0 10px

  &.single
    margin: 10px 0
    padding-left: 40px
    overflow: hidden

  @media (min-width: $breakpoint-tablet-portrait)
    margin: 40px 0 20px
    
    &.single
      margin: 20px 0
  
  .group
    align-self: center

.message
  margin-top: 40px

.enter
  margin-left: -20px

.submit
  align-self: center
  padding: 15px 40px
  margin: 0 20px
  font-size: 0.675rem
  font-weight: 600
  text-transform: uppercase
  background-color: $midnight
  color: $ivory
  margin-top: 40px

  &[disabled]
    opacity: 0.5

.error
  font-weight: 600
  color: $persimmon

.submit-container
  display: flex
  flex-direction: row
  justify-content: center
  align-items: center
  margin-top: 60px
  