@import ../../styles/15percent/_variables

.label
  fill: $nutmeg
  transition: opacity 0.3s ease-in-out 0.3s

  &.dark
    fill: $font-light

.collapsed
  .label
    opacity: 0