@import ../../styles/15percent/_variables

.header
  text-align: center

.container
  display: flex
  flex-direction: column
  align-items: center
  width: 100%

.step
  display: flex
  justify-content: center
  align-items: flex-start
  min-height: 50vh

.form
  margin: 60px 0 40px
  width: 100%
  min-width: 240px
  max-width: 360px

  @media (min-width: $breakpoint-tablet-portrait)
    width: 480px

.end
  align-self: flex-end

.message
  margin-top: 40px

.submit
  align-self: center
  margin-top: 60px

.btnreset
  width: 100%
  margin-top: 20px !important

.error
  font-weight: 600
  color: $orange
  margin-top: 0
