@import ../../styles/15percent/_variables

.header
  text-align: center

.container
  display: flex
  flex-direction: column
  align-items: center

  h2
    margin-top: 0

.step
  display: flex
  justify-content: center
  align-items: flex-start
  min-height: 50vh

.form
  width: 280px
  margin: 20px 0 0

  @media (min-width: $breakpoint-tablet-portrait)
    width: 480px
    margin: 40px 0 0
  
  .link
    color: $font-light
    opacity: 0.25
    margin-top: 5px
    user-select: none

    &.enabled
      opacity: 1
      user-select: auto

.submitcontainer
  display: flex
  width: 100%
  flex-direction: column
  justify-content: center
  align-items: center
  margin: 40px 0 0

  button
    margin: 10px

.link
  display: block
  width: calc(100% - 40px)
  padding-right: 40px
  color: $font-light
  white-space: nowrap
  overflow: hidden
  text-overflow: ellipsis
  border-bottom: 1px solid $bg-light !important

  &.enabled
    opacity: 1
    user-select: auto

.copy
  position: relative
  width: 100%
  min-width: 280px
  margin-top: 20px

  @media (min-width: $breakpoint-tablet-portrait)
    min-width: 360px
  
  button
    position: absolute
    right: 0
    bottom: 0
  
  .button
    color: $font-light
    border-radius: 100px
    border: 1px solid $bg-light
    font-size: 12px
    padding: 5px 10px

    &:disabled
      color: $antique-brass
      border-color: $antique-brass

    @media (min-width: $breakpoint-tablet-portrait)
      font-size: 0.675rem
      padding: 10px 20px

.copied
  font-size: 0.675rem
  color: $antique-brass
  margin-bottom: 0
  opacity: 0
  transition: opacity 0.3s ease-in-out

  &.show
    opacity: 1

.submit
  align-self: center
  padding: 15px 40px
  margin: 0 20px
  font-size: 0.675rem
  font-weight: 600
  text-transform: uppercase
  background-color: $midnight
  color: $ivory
  margin-top: 40px

  &[disabled]
    opacity: 0.5

.error
  font-weight: 600
  color: $persimmon
