@import ../../styles/15percent/_variables

.button
  position: fixed
  bottom: 20px
  right: 40px
  padding: 5px 16px
  border-radius: 50px
  background-color: $brown-rust-70
  color: $font-light
  font-size: 12px
  opacity: 0
  visibility: hidden
  transition: opacity 0.3s ease-in-out, visibility 0.05s linear 0.3s

  &.show
    visibility: visible
    opacity: 1
    transition: opacity 0.3s ease-in-out 0.1s, visibility 0.05s linear 0s

  @media (min-width: $breakpoint-tablet-portrait)
    right: 60px
  
  @media (min-width: $breakpoint-desktop)
    right: 100px
  
  @media (min-width: $max-content-width)
    right: calc((100vw - #{$breakpoint-desktop-large} - 100px) / 2 )